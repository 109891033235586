// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

Rails.start();
ActiveStorage.start();

function initializeReact() {
  const container = document.querySelector("#app");

  const properties = Object.keys(container.dataset).reduce((props, key) => {
    // eslint-disable-next-line no-unused-vars
    const [_, type, name] = key.match(
      /(javascript|boolean|number|date|json)*(.*)/
    );
    const keyName = `${name[0].toLowerCase()}${name.slice(1)}`;
    const value = container.dataset[key];

    switch (type) {
      case "boolean":
        return { ...props, [keyName]: value === "true" };
      case "number":
        return { ...props, [keyName]: parseFloat(value) };
      case "date":
        return { ...props, [keyName]: new Date(value) };
      case "json":
        return { ...props, [keyName]: JSON.parse(value) };
      default:
        return { ...props, [keyName]: value };
    }
  }, {});

  const root = createRoot(container);
  // eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
  root.render(<App {...properties} />);
}

(function contentLoaded() {
  window.addEventListener("DOMContentLoaded", initializeReact, false);
})();
